import React, { useState, useEffect, useRef } from 'react'
import { FormErrorMessage, Image } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import {
	FormLabel,
	InputGroup,
	InputLeftElement,
	FormHelperText,
	FormControl,
	Input,
	InputRightElement,
	Select,
	Flex,
	Box,
	InputLeftAddon,
} from '@chakra-ui/react'
import { getCountryTelCode } from './countries'
import { LocalPhoneRounded } from '../../../atoms/Icon'

const PhoneNumber = ({
	name,
	size,
	label,
	control,
	setError,
	clearErrors,
	errors,
	helperText,
	placeholder,
	defaultValue,
	register,
	addPrefix,
	...rest
}) => {
	/**
	 * Handle Error Messages
	 * */
	let isError = false
	let errorMessage = ''
	let countryErrorMessage = ''
	if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorMessage = errors[name].message
	}

	if (errors && errors.hasOwnProperty('smsCountryCode') === true) {
		isError = true
		countryErrorMessage = errors['smsCountryCode'].message
	}

	/**
	 * Retrieve control handlers
	 * */
	const { setValue, getValues } = control

	/**
	 * Retrieve values of the form
	 * */
	const values = getValues()
	const value = values[name]
	const valueCountryCode = values['smsCountryCode'] || values['countryCode'] || 'US'

	/**
	 * Get usable countries
	 */
	const countries = [
		{
			flag: 'https://flagcdn.com/ca.svg',
			label: 'Canada',
			prefix: '+1',
			value: 'CA',
		},
		{
			flag: 'https://flagcdn.com/us.svg',
			label: 'United States of America',
			prefix: '+1',
			value: 'US',
		},
	]

	/**
	 * Handler to programmatically update the input value
	 * */
	const onChange = (val) => {
		setValue(name, val)
	}

	/**
	 * Manage Input state
	 * */
	const [isSelectInvalid, setIsSelectInvalid] = useState(false)
	let [number, setNumber] = useState(value || '')
	let [selectedCountry, setSelectedCountry] = useState()
	let [countryPrefix, setCountryPrefix] = useState()
	let [countryObject, setCountryObject] = useState()

	useEffect(() => {
		if (value) {
			setNumber(value)
		}
	}, [value])

	useEffect(() => {
		if (valueCountryCode) {
			setCountryObject(countries.find((el) => el?.value == valueCountryCode))
		}
		if (valueCountryCode && countries.length > 0) {
			let obj = countries.find((el) => el?.value == valueCountryCode)
			setCountryObject(obj) // set country object
			setCountryPrefix(obj?.prefix)
			setSelectedCountry(valueCountryCode)
			setIsSelectInvalid(false)
		}
	}, [valueCountryCode])

	const onCountryChange = (e) => {
		let val = e.target.value
		let code = getCountryTelCode(val, countries)
		setCountryPrefix(code) // save phone prefix
		setSelectedCountry(val) // save Alpha 2 country code
		setCountryObject(countries.find((el) => el?.value == val)) // Save a reference to the country object
		setValue('smsCountryCode', val) // Set the value of the `smsCountryCode` in react-hook-form
	}

	const onPhoneNumberChange = (e) => {
		let val = e.target.value
		// let parsedNumber = new AsYouType().input(`${countryPrefix}${value}`)
		let formatted = val.replaceAll(/[^0-9]/g, '')
		setNumber(formatted) // save value in local state
		onChange(`${addPrefix ? countryPrefix : ''}${formatted}`) // save value in react-hook-form
	}

	const handleFocusOnSelect = () => {
		if (!valueCountryCode && !control) {
			setIsSelectInvalid(true)
			setError('smsCountryCode', { type: 'error', message: 'Please select a country first.' })
		} else clearErrors('smsCountryCode')
	}

	useEffect(() => {
		if (valueCountryCode) {
			clearErrors('smsCountryCode')
		}
	}, [valueCountryCode])

	return (
		<Controller
			id={name}
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={(props, { invalid, isTouched, isDirty }) => {
				return (
					<FormControl isInvalid={isError}>
						{label && <FormLabel htmlFor={name}>{label}</FormLabel>}

						<InputGroup size={size} {...rest} minW="150px" position="relative" pl="70px">
							<InputLeftElement width={'4rem'} maxW="64px">
								<Select
									{...register('smsCountryCode')}
									top="0"
									left="0"
									zIndex={1}
									bottom={0}
									opacity={0}
									minW="75px"
									name="smsCountryCode"
									position="absolute"
									value={selectedCountry}
									onChange={onCountryChange}
									height="var(--vidday-sizes-10)"
									iconColor={isSelectInvalid || countryErrorMessage ? 'red' : 'inherit'}>
									{countries.map((option, i) => (
										<option key={i} value={option?.value}>
											{option.label}
										</option>
									))}
								</Select>
								<Flex pl={2} width="100%" alignItems="center">
									{!selectedCountry && isSelectInvalid && (
										<Box mr="4px" width="50%" flex={1}>
											<Box w="40px" h="30px" bg="red.100" borderRadius="base"></Box>
										</Box>
									)}
									{selectedCountry && (
										<Box mr="4px" width="50%" flex={1}>
											<Image
												maxW="32px"
												src={countryObject && countryObject?.flag}
												alt={countryObject && countryObject?.value}
												boxShadow="base"
											/>
										</Box>
									)}
								</Flex>
							</InputLeftElement>
							<InputLeftAddon children={countryPrefix} />
							<Input
								isReadOnly={!valueCountryCode && !countryErrorMessage}
								pl="0.5rem"
								name={name}
								type="tel"
								{...props}
								onClick={handleFocusOnSelect}
								value={number}
								placeholder={placeholder}
								onChange={onPhoneNumberChange}
							/>

							<InputRightElement width="2.5rem">
								<LocalPhoneRounded color="gray.300" />
							</InputRightElement>
						</InputGroup>

						{isError ? (
							<FormErrorMessage pl="70px" display="block" textAlign="left">
								{errorMessage || countryErrorMessage}
							</FormErrorMessage>
						) : (
							helperText && <FormHelperText pl="70px">{helperText}</FormHelperText>
						)}
					</FormControl>
				)
			}}
		/>
	)
}

export default PhoneNumber
