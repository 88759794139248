import React, { createContext, useContext, useState, useEffect } from 'react'
import { calculateCountdown } from '../ui/pages/public/Active/molecules/Countdown/utils'

/**
 * Countdown hook that returns an object of {day, hour, min, sec}
 * */
const useCountdown = (date) => {
	const [day, setDay] = useState(0)
	const [hour, setHour] = useState(0)
	const [min, setMin] = useState(0)
	const [sec, setSec] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			const _date = calculateCountdown(date)
			setDay(_date.day)
			setHour(_date.hour)
			setMin(_date.min)
			setSec(_date.sec)
			// setDay(0)
			// setHour(0)
			// setMin(0)
			// setSec(0)
		}, 1000)

		return () => clearInterval(interval)
	}, [])

	return { day, hour, min, sec }
}

/** Build Current Event Context */
const CountdownContext = createContext()

/** Define Current Event Context Provider  */
const CountdownProvider = ({ date, children }) => {
	const value = useCountdown(date)

	return <CountdownContext.Provider value={value}>{children}</CountdownContext.Provider>
}

export default CountdownProvider

export const useCountdownContext = () => useContext(CountdownContext)
