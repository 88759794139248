import React from 'react'
import { addLeadingZeros } from './utils'
import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import CountdownProvider, { useCountdownContext } from '../../../../../../contexts/CountdownProvider'
import { useEventContext } from '../../../../../../contexts/EventProvider'
import eventWithin24h from '../../../../../../utils/eventWithin24h'

const headingStyles = {
	fontSize: '1.75rem',
	textAlign: 'center',
}

const CountdownTimer = ({ role }) => {
	const date = useCountdownContext()
	const { event } = useEventContext()
	const { day, hour, min, sec } = date
	const hasNoDate = day == 0 && hour == 0 && min == 0 && sec == 0
	const isWithin24h = eventWithin24h(event)

	return (
		<Box textAlign="center">
			{!isWithin24h && !hasNoDate ? (
				<>
					<HStack justifyContent="center">
						<Heading sx={{ ...headingStyles }}>{addLeadingZeros(day)}d</Heading>
						<Heading sx={{ ...headingStyles }}>{addLeadingZeros(hour)}h</Heading>
						<Heading sx={{ ...headingStyles }}>{addLeadingZeros(min)}m</Heading>
					</HStack>
					{role == 'creator' && <Text>Submissions are accepted until you finalize.</Text>}
				</>
			) : (
				<>
					<Heading sx={{ ...headingStyles, fontSize: '1.5rem' }} minW={['280px', 'max-content']}>
						Submissions close {isWithin24h ? 'today' : 'soon'} — submit now.
					</Heading>
					{role == 'creator' && <Text>Submissions are accepted until you finalize.</Text>}
				</>
			)}
		</Box>
	)
}

const CountdownWrapper = ({ label, children, ...rest }) => {
	const { day, hour, min, sec } = useCountdownContext()

	const hasNoDate = day == 0 && hour == 0 && min == 0 && sec == 0

	return (
		<Box w="full" pos="relative" pt="1rem" {...rest}>
			<VStack spacing={hasNoDate ? '0.8rem' : '0.5rem'}>
				<Text color="gray.900" fontWeight="bold" minW="max-content">
					{label}
				</Text>
				{children}
			</VStack>
		</Box>
	)
}

CountdownWrapper.defaultProps = {
	label: 'Submissions are due:',
}

const Countdown = ({ date, children }) => {
	return <CountdownProvider date={date}>{children}</CountdownProvider>
}

export default Countdown

export { CountdownWrapper, CountdownTimer }
