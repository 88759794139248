import React from 'react'
import { Box, SimpleGrid, Text, Image, Flex, HStack, useDisclosure, Button } from '@chakra-ui/react'
import useImageGenerator from '../../../../../../hooks/useImageGenerator'
import { LightButton } from '../../../../../atoms/LightButton'
import ModalVideo from '../../../../../organisms/ModalVideo'
import { PlayArrowRounded } from '../../../../../atoms/Icon'

const items = [
	{
		title: 'How It Works',
		duration: '1:30',
		img: '/assets/images/graphics/how-it-works-small',
		path: 'https://static.vidday.com/videos/vidday-contributor-explainer-video.mp4',
		poster: 'https://static.vidday.com/videos/vidday-contributor-explainer-video.jpg',
	},
	{
		title: 'Example Video',
		duration: '4:30',
		img: '/assets/images/graphics/video-sample-image-small',
		path: 'https://static.vidday.com/videos/vidday-group-video-gift-example.mp4',
		poster: 'https://static.vidday.com/videos/vidday-group-video-gift-example.jpg',
	},
]

const VideoBlock = ({ item: { img, title, duration, path, link } }) => {
	const src = useImageGenerator({ path: img, ext: 'png' })
	const { isOpen, onClose, onToggle } = useDisclosure()
	const handleOnClick = () => {
		path ? onToggle() : window.open(link, '_blank')
	}

	return (
		<Box
			w="full"
			__css={{
				'*': {
					cursor: 'pointer !important',
				},
			}}
			onClick={handleOnClick}>
			<Flex
				alignItems="center"
				w="full"
				justifyContent="space-between"
				borderColor="whiteAlpha.300"
				borderWidth="1px"
				borderRadius="var(--vidday-radii-xl)"
				p="0.5rem"
				pr="1rem">
				<HStack spacing="1rem">
					<Image src={src.src} srcSet={src.srcset} boxSize="64px" borderRadius="md" />
					<Box>
						<Text color="white" fontWeight="bold" fontSize="1.125rem">
							{title}
						</Text>
						<Text fontSize="0.875rem">{duration}</Text>
					</Box>
				</HStack>
				<LightButton Icon={PlayArrowRounded} />
				<ModalVideo size="2xl" autoPlay path={path || null} isOpen={isOpen} onClose={onClose} />
			</Flex>
		</Box>
	)
}

const HowToVideos = ({ ...rest }) => {
	return (
		<SimpleGrid columns={[1, 2]} spacing="1rem" {...rest}>
			<VideoBlock item={items[0]} />
			<VideoBlock item={items[1]} />
		</SimpleGrid>
	)
}

export default HowToVideos
