/* ******************************
 * METHOD > CalculateCountdown
 ***************************** */

export function calculateCountdown(endDate) {
	let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000

	const timeLeft = {
		year: 0,
		day: 0,
		hour: 0,
		min: 0,
		sec: 0,
		millisec: 0,
	}

	// clear countdown when date is reached
	if (diff <= 0) return timeLeft

	// calculate time difference between now and expected date
	if (diff >= 365.25 * 86400) {
		// 365.25 * 24 * 60 * 60
		timeLeft.year = Math.floor(diff / (365.25 * 86400))
		diff -= timeLeft.year * 365.25 * 86400
	}
	if (diff >= 86400) {
		// 24 * 60 * 60
		timeLeft.day = Math.floor(diff / 86400)
		diff -= timeLeft.day * 86400
	}
	if (diff >= 3600) {
		// 60 * 60
		timeLeft.hour = Math.floor(diff / 3600)
		diff -= timeLeft.hour * 3600
	}
	if (diff >= 60) {
		timeLeft.min = Math.floor(diff / 60)
		diff -= timeLeft.min * 60
	}
	timeLeft.sec = diff

	return timeLeft
}

export const addLeadingZeros = (value) => {
	let val = String(value)
	while (value.length < 2) {
		val = '0' + value
	}
	return val
}
