import { Box, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { useEventPrompts } from '../../../../../../hooks/useEventPrompts'
import { useEventRecipients } from '../../../../../../hooks/useRecipients'

const IdeaContent = ({ ...rest }) => {
	const recipients = useEventRecipients()
	const prompts = useEventPrompts()

	const promptsList = () => {
		const list = prompts?.map((p, i) => {
			if (p.prompt && p.prompt != '' && typeof p?.prompt === 'string') {
				let text = p?.prompt?.replaceAll ? p.prompt.replaceAll('%recipient%', recipients.firstname) : ' '
				return (
					<Text fontSize="18px" key={i} color="dark">
						💡 {text}
					</Text>
				)
			}
		})
		return list?.filter((item) => typeof item === 'object') || []
	}

	let list = promptsList()

	if (list.length) {
		return (
			<Box w="full" {...rest}>
				<Text color="dark" fontSize="1.125rem" fontWeight="bold" mb="1rem">
					Ideas For Your Message
				</Text>
				<VStack spacing="0.5rem" alignItems="flex-start">
					{list}
				</VStack>
			</Box>
		)
	} else {
		return <></>
	}
}

export default IdeaContent
