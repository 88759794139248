import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text, Link } from '@chakra-ui/react'

const styles = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	my: '1rem',
	pb: '1rem',
	mx: ['1rem', 0],
}

const StyledExternalLink = ({ link, titleText, linkText }) => {
	return (
		<Box sx={styles}>
			<Text fontSize="xs" color="black">
				{titleText}
			</Text>
			<Text fontSize="xs">
				<Link fontWeight="bold" color="link" href={link} isExternal>
					{linkText}
				</Link>
			</Text>
		</Box>
	)
}

StyledExternalLink.propTypes = {
	link: PropTypes.string,
	titleText: PropTypes.string,
	linkText: PropTypes.string,
}

StyledExternalLink.defaultProps = {
	titleText: 'All charges are in USD. All gift card sales are final. Fees apply.',
	linkText: 'Learn More',
	link: 'https://help.vidday.com/what-are-the-gift-card-fees/',
}

export default StyledExternalLink
