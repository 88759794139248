import React from 'react'
import { IconButton } from '@chakra-ui/react'
import { ArrowForwardRounded } from '../Icon'

export const LightButton = ({ Icon, ...rest }) => {
	return (
		<IconButton
			icon={<Icon w="1rem" color="white" />}
			sx={{
				'w': '2rem',
				'h': '2rem',
				'maxH': '2rem',
				'maxW': '2rem',
				'minW': '2rem',
				'backgroundColor': 'rgba(255, 255, 255, 0.1)',
				'color': 'white',
				'&:hover': {
					backgroundColor: 'white',
					svg: {
						fill: 'link',
					},
				},
			}}
			{...rest}
		/>
	)
}
LightButton.defaultProps = {
	Icon: ArrowForwardRounded,
}
