import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { chakra, Box, SimpleGrid, Button, LightMode, HStack, Flex, Spinner } from '@chakra-ui/react'
import Input from '../../../../../../molecules/controls/Input'
import Checkbox from '../../../../../../molecules/controls/Checkbox'
import SimpleEmail from '../../../../../../molecules/controls/InputEmail/SimpleEmail'
import { useTemporaryUserContext } from '../../../../../../../contexts/TemporaryUserProvider'

const ContributorCaptureForm = ({ onSubmit }) => {
	const account = useTemporaryUserContext()

	/** Define form configuration and hooks  */
	const { control, handleSubmit, errors, setValue, formState } = useFormContext()

	/**
	 * Update form with temp user
	 * */
	useEffect(() => {
		if (account) {
			account.email && setValue('email', account.email)
			account.profile?.firstName && setValue('firstName', account.profile.firstName)
			account.profile?.lastName && setValue('lastName', account.profile.lastName)
			account.profile?.notifications?.promos &&
				setValue('notifications[promos]', account.profile.notifications.promos)
		}
	}, [account])

	return (
		<Box w="full">
			<chakra.form onSubmit={handleSubmit(onSubmit)}>
				<SimpleGrid columns={[1, 3]} spacing="1rem" mb="0.5rem">
					<Input control={control} errors={errors} defaultValue="" name="firstName" label="Your First Name" />
					<Input control={control} errors={errors} defaultValue="" name="lastName" label="Your Last Name" />
					<SimpleEmail
						control={control}
						errors={errors}
						defaultValue=""
						name="email"
						label="Your Email"
						mb="1rem"
					/>
				</SimpleGrid>

				<Flex direction="column" alignItems="flex-start">
					<Checkbox
						mb="1rem"
						control={control}
						errors={errors}
						name="notifications[promos]"
						description={
							<chakra.span color="gray.900">
								<strong>Stay in the loop</strong> with emails of special offers, updates and promos.
							</chakra.span>
						}
					/>

					<Checkbox
						mb="1rem"
						control={control}
						errors={errors}
						name="useConsent"
						description={
							<chakra.span color="gray.900">
								<strong>Inspire others -</strong> let VidDay use your video clip for marketing. If
								selected, you'll get a free VidDay.
							</chakra.span>
						}
					/>
				</Flex>

				<HStack w="full" mt="2rem" justifyContent="center">
					<LightMode>
						<Button
							type="submit"
							variant="solid"
							minW="150px"
							isDisabled={formState.isSubmitting}
							isLoading={formState.isSubmitting}
							loadingText={<span>Submitting</span>}>
							<span>Finish</span>
						</Button>
					</LightMode>
				</HStack>
			</chakra.form>
		</Box>
	)
}

export default ContributorCaptureForm
