import React from 'react'
import { Container, Box, Image, Text, Heading, VStack } from '@chakra-ui/react'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { InvitationCard } from '../../../../molecules/Envelope'
import { Contributor } from '../../../InviteList/molecules/Individual'
import { Helmet } from 'react-helmet'
import { useEventRecipients } from '../../../../../hooks/useRecipients'
import useImageGenerator from '../../../../../hooks/useImageGenerator'
import useScrollTopOnMount from '../../../../../hooks/useScrollTopOnMount'

const RSVP = () => {
	useScrollTopOnMount()
	// const dispatch = useDispatch()
	const { event } = useEventContext()
	let creator = event.creator?.profile
	let creatorName = creator ? `${creator.firstName}` : 'them'
	const recipients = useEventRecipients()

	const img = useImageGenerator({
		path: '/assets/images/graphics/rsvp-graphic',
		ext: 'png',
	})
	return (
		<>
			<Helmet>
				<title>VidDay - RSVP for {recipients.formatted}</title>
			</Helmet>

			<Container maxW="container.sm" pb="1rem" variant="main" px="1rem">
				<InvitationCard>
					<Box w="full" py="2rem" px={['1rem', '2rem']}>
						<VStack spacing="1rem">
							<Image src={img.src} srcSet={img.srcset} boxSize="64px" />

							<Heading variant="hero" color="dark" as="h3" size="lg" mb="2rem">
								RSVP
							</Heading>

							<Text color="dark" textAlign="center" maxW="510px">
								<strong>Let {creatorName} know you'll submit soon</strong> and we'll send you friendly
								reminders so you don't forget. 😉
							</Text>
							<Contributor />
						</VStack>
					</Box>
				</InvitationCard>
			</Container>
		</>
	)
}

export default RSVP
