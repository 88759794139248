import { useEffect, useState } from 'react'
import { useLocation, useSearchParams, useHistory } from 'react-router-dom'
import { history } from '../../history'

const useStripeResponse = (forwardRef) => {
	const [searchQuery, setSearchQuery] = useSearchParams()
	const location = useLocation()

	/** Set error statuses */
	const [error, setError] = useState(searchQuery.has('stripe_error') || false)
	const [errorMessage, setErrorMessage] = useState('')

	/** Set success statuses */
	const [success, setSuccess] = useState(searchQuery.has('stripe_success') || false)
	const [successMessage, setSuccessMessage] = useState('')

	/**
	 * Handler allowing setting an error message and switching status to error
	 * @param {*} message
	 */
	const setStripeError = (message) => {
		setSuccess(false)
		setSuccessMessage('')
		setError(true)
		setErrorMessage(message)
	}

	/**
	 * Handler allowing setting a success message and switching status to success
	 * @param {*} message
	 */
	const setStripeSuccess = (message) => {
		setError(false)
		setErrorMessage('')
		setSuccess(true)
		setSuccessMessage(message)
	}

	useEffect(() => {
		// consume the stripe_error search param
		if (searchQuery.has('stripe_error')) {
			setStripeError("Sorry, we couldn't process your payment.")
			searchQuery.delete('stripe_error')
		}

		// consume the stripe_success search param
		if (searchQuery.has('stripe_success')) {
			setStripeSuccess('Your purchase was successful!')
			searchQuery.delete('stripe_success')
		}

		// update search query
		setSearchQuery(searchQuery)
	}, [searchQuery])

	useEffect(() => {
		if (forwardRef?.current) {
			if (error || success) {
				setTimeout(() => {
					if (typeof window !== 'undefined' && window) {
						const yOffset = 230
						const element = forwardRef?.current
						const y = element?.getBoundingClientRect().top + window.pageYOffset - yOffset
						window.scrollTo({ top: y, behavior: 'smooth' })
					}
				}, 3000)
			}
		}
	}, [forwardRef?.current, error, success])

	return {
		/** Error statuses */
		setError: setStripeError,
		error: error,
		errorMessage: errorMessage,
		/** Success statuses */
		setSuccess: setStripeSuccess,
		success: success,
		successMessage: successMessage,
	}
}

export default useStripeResponse
