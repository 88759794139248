import React from 'react'
import useImageGenerator from '../../../../../../hooks/useImageGenerator'
import { Box, Image, VStack, Text, LightMode, Button, Icon, HStack } from '@chakra-ui/react'
import { isMobile } from 'react-device-detect'

const FindUniqueGiftsBox = ({ event }) => {
	const img = useImageGenerator({
		path: `/assets/images/graphics/${
			isMobile ? 'submission-mobile-othergifts-cover' : 'submission-unique-gifts-cover'
		}`,
		ext: 'jpg',
	})

	return (
		<>
			<Image src={img.src} srcSet={img.srcset} w="full" />
			<Box w="full" p="2rem">
				<Box color="dark" w="full" textAlign="center" mb="1rem" maxW="540px" mx="auto">
					<Text fontWeight="bold" variant="title">
						Find more unique gifts.
					</Text>
					<Text>
						Shop VidDay's collection of curated gifts! With an array of personalized gifts there's something
						for everyone.
					</Text>
				</Box>
				<HStack w="full" justifyContent="center">
					<LightMode>
						<Button
							variant="outline"
							size="sm"
							as="a"
							target="_blank"
							href={`https://www.vidday.com/gift-shop?occasion=${event.occasion}&uuid=${event.uuid}`}>
							Browse Gift Shop
						</Button>
					</LightMode>
				</HStack>
			</Box>
		</>
	)
}

export default FindUniqueGiftsBox
