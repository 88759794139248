import useSSR from 'use-ssr'

/**
 * hook to push begin_checkout related events into data layer
 * @param {string} checkoutSessionId the id of the Stripe Checkout session
 */

export const useBeginCheckoutEvent = (checkoutSessionId) => {
	const { isBrowser } = useSSR()
	if (isBrowser) {
		let dataLayer = window.dataLayer || []
		dataLayer.push({
			event: 'begin_checkout',
			checkout_session_id: checkoutSessionId,
		})
	}
}

export default useBeginCheckoutEvent
