import * as Yup from 'yup'

const schemaContributorRecipient = Yup.object().shape({
	email: Yup.string()
		.email('Please provide a valid email address')
		.required()
		.nullable()
		.trim('Please remove all spaces.'),
	firstName: Yup.string().required('You need to provide a first name.'),
	lastName: Yup.string().required('You need to provide a last name.'),
})

export default schemaContributorRecipient
