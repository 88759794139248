import React, { useEffect } from 'react'
import PublicHeader from '../atoms/PublicHeader'
import FooterBase from '../../../molecules/FooterBase'
import { Button, Stack, VStack, Text, Container, Heading, Box, LightMode, Tooltip } from '@chakra-ui/react'
import HelpfulArticles from './molecules/HelpfulArticles'
import MessageIdea from './molecules/MessageIdea'
import InvitationHeader from '../../../molecules/InvitationHeader'
import Countdown, { CountdownWrapper, CountdownTimer } from './molecules/Countdown'
import { useEventContext } from '../../../../contexts/EventProvider'
import useInvitationMessage from '../../../../hooks/useInvitationMessage'
import { useEventRecipients } from '../../../../hooks/useRecipients'
import { useEventOccasion } from '../../../../hooks/useEventOccasion'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/utc'
import { useRoleContext } from '../../../../contexts/RoleProvider'
import InvitationEnvelope, { InvitationCard } from '../../../molecules/Envelope'
// import GiftCardVisibility from './molecules/GiftCardVisibility'
import GiftCardContributionBox from './molecules/GiftCardContributionBox'
import HowToVideos from './molecules/HowToVideos'
// import PreviewControls from './molecules/PreviewControls'
import IdeaContent from './molecules/IdeaContent'
import { Routes, Route, useNavigate, matchPath, useLocation, Navigate } from 'react-router-dom'
import RSVP from './RSVP'
import RSVPSuccess from './RSVPSuccess'
import { useEventHandlersV2 } from '../../../../hooks/useEventHandlers'
import Submit from './Submit'
// import Invite from './Invite'
import loadable from '@loadable/component'
import { useParams } from 'react-router-dom'
import useScrollTopOnMount from '../../../../hooks/useScrollTopOnMount'
import useNumberSuffix from '../../../../hooks/useNumberSuffix'

// import EventHeader from '../../../organisms/event-header'
const EventHeader = loadable(() => import(/* webpackPrefetch: true */ '../../../organisms/event-header/index.tsx'), {
	ssr: false,
})
const Contribute = loadable(() => import(/* webpackPrefetch: true */ '../Active/Contribute'), { ssr: false })
const Invite = loadable(() => import('./Invite'), { ssr: false })

const PreviewControls = loadable(() => import(/* webpackPrefetch: true */ './molecules/PreviewControls'), {
	ssr: false,
})
const GiftCardVisibility = loadable(() => import(/* webpackPrefetch: true */ './molecules/GiftCardVisibility'), {
	ssr: false,
})

// extends dayjs with utc and timezone plugins.
dayjs.extend(utc)
dayjs.extend(timezone)

const Root = () => {
	const { event, isOwner } = useEventContext()
	const recipients = useEventRecipients()
	const eventOccasion = useEventOccasion()
	const { role } = useRoleContext()
	const navigate = useNavigate()
	const handlers = useEventHandlersV2(event.uuid)
	const yearSuffix = useNumberSuffix(event.years)
	const { inviteMessage } = useInvitationMessage()

	let creator = event.creator?.profile
	let creatorName = creator ? `${creator.firstName}` : 'them'
	const tip = isOwner ? 'You are in preview mode.' : ''
	let eventTimezone = event?.timezone ? event?.timezone : dayjs.tz.guess()

	return (
		<>
			<EventHeader hideCoverPhoto={true} />
			<InvitationCard mb="1rem">
				{/* <InvitationHeader role={role} event={event} recipients={recipients} /> */}
				<Box w="full" py="2rem" px={['1rem', '2rem']}>
					{/* <VStack w="full" spacing="0.25rem" alignItems="center" mb="2rem">
						<Text color="dark" fontSize="1.125rem" fontWeight="bold">
							{event.years ? event.years + yearSuffix + ' ' : ''}
							{eventOccasion.label} Video for
						</Text>
						<Heading color="dark" as="h2" variant="hero" size="xl" textAlign="center">
							{recipients.formatted}
						</Heading>
					</VStack> */}

					<Box
						w="full"
						p="0"
						mb="2rem"
						resize="none"
						color="dark"
						fontSize="18px"
						variant="unstyled"
						bg="transparent"
						userSelect="text"
						whiteSpace="break-spaces"
						_focusVisible={{
							outline: 'none',
						}}>
						{inviteMessage}
					</Box>

					<IdeaContent mb="2rem" />

					{event?.deadline && (
						<Countdown date={dayjs(event?.deadline).tz(eventTimezone)}>
							<CountdownWrapper mb="2rem">
								<CountdownTimer role={role} />
							</CountdownWrapper>
						</Countdown>
					)}

					<Stack
						mb="1rem"
						direction={['column-reverse', 'row']}
						spacing="1rem"
						w="full"
						justifyContent="center">
						<LightMode>
							<Tooltip
								label={tip}
								aria-label={tip}
								hasArrow
								arrowSize={12}
								placement="top"
								fontSize="0.86rem"
								p=".5rem"
								rounded={'md'}>
								<Button variant="outline" minW="150px" onClick={() => navigate(handlers.public_rsvp)}>
									RSVP
								</Button>
							</Tooltip>
							<Tooltip
								label={tip}
								aria-label={tip}
								hasArrow
								arrowSize={12}
								placement="top"
								fontSize="0.86rem"
								p=".5rem"
								rounded={'md'}>
								<Button
									variant="solid"
									minW="150px"
									onClick={() => navigate(handlers.public_contribute)}>
									Record or Upload
								</Button>
							</Tooltip>
						</LightMode>
					</Stack>
					<VStack textAlign="center">
						<Text color="gray.900" fontWeight="bold">
							Not ready yet?
						</Text>
						<Text fontSize="14px" color="#4A555F" lineHeight="1.25rem">
							Please RSVP and we'll let {creatorName} know you plan on participating.
						</Text>
					</VStack>
				</Box>

				{isOwner ? (
					<GiftCardVisibility event={event}>
						<GiftCardContributionBox role={role} />
					</GiftCardVisibility>
				) : (
					<>
						{role == 'public' && event.cashCollectionGroup == 'everyone' && (
							<GiftCardContributionBox role={role} />
						)}
					</>
				)}
			</InvitationCard>
		</>
	)
}

const Active = () => {
	useScrollTopOnMount()
	const { event } = useEventContext()
	const { isOwner } = useEventContext()
	const location = useLocation()
	const isRootPage = matchPath('/e/:uuid', location.pathname)
	const { uuid } = useParams()

	const isRoot = location.pathname == `/e/${event.uuid}`

	return (
		<>
			<PreviewControls />

			<PublicHeader withNewHeader={isRoot} />

			<Container maxW="container.md" pb="1rem" variant="main" px="1rem">
				<InvitationEnvelope>
					<Routes>
						<Route index element={<Root />} />
						<Route path="rsvp" element={<RSVP />} />
						<Route path="rsvp/success" element={<RSVPSuccess />} />
						<Route path="contribute" element={<Contribute />} />
						<Route path="upload" element={<Navigate to={`/e/${uuid}/contribute${location.search}`} />} />
						<Route path="submit" element={<Submit />} />
						<Route path="invite" element={<Invite />} />
					</Routes>

					{isRootPage && (
						<>
							<HowToVideos mb="1rem" />
							<MessageIdea mb="1rem" />
							<HelpfulArticles />
						</>
					)}
				</InvitationEnvelope>

				<FooterBase />
			</Container>
		</>
	)
}

export default Active
