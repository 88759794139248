import * as Yup from 'yup'
import config from '../../../../../../client/config.client'

export const baseSchema = {
	/** Lets figure if we're collecting money or not */
	isCollecting: Yup.boolean().required(),
	amount: Yup.mixed().when('isCollecting', {
		is: (val) => val == true,
		then: Yup.mixed().oneOf([15, 25, 50, 'custom']).required(),
		otherwise: Yup.number().default(0), //notRequired()
	}),
	customAmount: Yup.number().when('amount', {
		is: (val) => val == 'custom',
		then: Yup.number()
			.required('Please provide an amount.')
			.typeError('Please enter a valid number')
			.min(5, 'Amount must be at least $5.00.')
			.max(
				config.tremendous.contributionCap / 100,
				`Amount can not exceed $${config.tremendous.contributionCap / 100}.`
			),
		otherwise: Yup.number().optional(),
	}),
	anonymous: Yup.boolean().required(),
}

const schemaAmount = Yup.object().shape(baseSchema).required()

export default schemaAmount
