import React from 'react'
import { chakra, Text } from '@chakra-ui/react'
import { FeatureItemFullWidth } from '../../../../../molecules/FeatureItem'

const messageIdea = {
	path: '/assets/images/graphics/message-idea',
	content: () => (
		<>
			<Text variant="title">Find the perfect words.</Text>
			<Text pt=".25rem">Try our handy message idea generator.</Text>
		</>
	),
	target: '',
	buttonText: 'Get Message Ideas',
}

const MessageIdea = ({ ...rest }) => {
	const data = {
		...messageIdea,
		onClick: () => {
			window.open('https://www.vidday.com/wish-ideas-generator', '_blank')
		},
	}
	return <FeatureItemFullWidth item={data} isFullyClickable {...rest} />
}

export default MessageIdea
