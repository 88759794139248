import React, { useEffect } from 'react'
import CardServices from '../CardServices'
import SelectDonationAmount from '../SelectDonationAmount'
import { VStack, Checkbox, FormErrorMessage, DarkMode } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { scrollToElement } from '@vidday/utils'
import { FormLabel, InputGroup, InputLeftElement, FormHelperText, FormControl, Input } from '@chakra-ui/react'

const ControlledCheckbox = (props) => {
	/** Retrieve props passed from parent */
	const { control, name, label, scrollToOnFocus, errors, required, children } = props

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={props.value}
			render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => {
				return (
					<Checkbox
						onBlur={onBlur}
						onChange={(e) => onChange(e.target.checked)}
						isChecked={value}
						onFocus={scrollToOnFocus ? (e) => scrollToElement({ current: e.target }, -120) : null}>
						{children}
					</Checkbox>
				)
			}}
		/>
	)
}

const ControlledInput = (props) => {
	const { control, name, label, scrollToOnFocus, errors, required, children } = props

	console.log(errors, 'errors', name, errors[name])
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={props.value}
			render={(props, { invalid, isTouched, isDirty }) => {
				return (
					<FormControl isInvalid={errors[name]}>
						<FormLabel htmlFor={name} textAlign="center">
							How much would you like to add?
						</FormLabel>
						<InputGroup>
							<InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em" children="$" />
							<Input id={name} placeholder="Enter amount" {...props} />
							{/* <InputRightElement children={<CheckIcon color='green.500' />} /> */}
						</InputGroup>
						{errors[name] ? (
							<FormErrorMessage display="block">{errors[name].message}</FormErrorMessage>
						) : (
							<FormHelperText>Please enter an amount</FormHelperText>
						)}
					</FormControl>
				)
			}}
		/>
	)
}

const DonationForm = ({ formInstance }) => {
	const { control, register, handleSubmit, errors, setValue, watch, setError, clearErrors } = formInstance

	/** Watch changes in form values */
	const values = watch()

	/** Handler to update custom buttons */
	const handleSetItem = (val) => {
		if (values.amount == val) {
			setValue('isCollecting', false)
			setValue('amount', 0) // Reset value
		} else {
			setValue('isCollecting', true)
			setValue('amount', val) // Set value
		}
	}

	/** Register custom fields */
	useEffect(() => {
		register({ name: 'isCollecting' }, { required: true })
		register({ name: 'amount' }, { required: true })
		register({ name: 'customAmount' }, { required: true })
	}, [register])

	return (
		<VStack direction="column" spacing="1rem">
			<SelectDonationAmount value={values.amount} setValue={handleSetItem} />
			<CardServices />

			{values.amount != 0 && (
				<DarkMode>
					{values.amount == 'custom' && (
						<ControlledInput control={control} errors={errors} name="customAmount" />
					)}

					<ControlledCheckbox control={control} errors={errors} name="anonymous" scrollToOnFocus>
						I wish to remain anonymous
					</ControlledCheckbox>
				</DarkMode>
			)}
		</VStack>
	)
}

export default DonationForm
