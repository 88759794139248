import React, { useEffect, useState } from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { updateEvent } from '../../../../../../../api/app/events'
import Toggle from '../../../../../atoms/Toggle'
import { LockRounded, LockOpenRounded } from '../../../../../atoms/Icon'

const GiftCardVisibility = ({ event, role, children }) => {
	const [toggled, setToggled] = useState(event.allowCashCollection)
	const dispatch = useDispatch()

	useEffect(() => {
		if (event && event.cashCollectionGroup == 'everyone') {
			setToggled(true)
		} else setToggled(false)
	}, [event])

	const handleToggle = () => {
		const isToggle = !toggled
		setToggled(isToggle)
		dispatch(updateEvent({ id: event.id, cashCollectionGroup: isToggle ? 'everyone' : 'creator' }))
	}

	const inactiveStyles = {
		opacity: '0.5',
		cursor: 'default',
		pointerEvents: 'none',
	}

	return (
		<>
			<Flex w="full" justify="space-between" bg="gray.100" py="1rem" px={['1rem', '2rem']} alignItems="center">
				<Box>
					<Text color="#6C7A88" display="flex" alignItems="center" fontSize="0.75rem">
						{toggled ? (
							<LockOpenRounded mr="0.25rem" boxSize="1rem" />
						) : (
							<LockRounded mr="0.25rem" boxSize="1rem" />
						)}
						Only you can see this:
					</Text>
					<Text color="dark" fontWeight="bold" fontSize="0.875rem">
						{toggled ? 'Group Gift Card is active' : 'Group Gift Card is not active'}
					</Text>
				</Box>
				<Toggle value={toggled} onToggle={handleToggle} theme="visibility" />
				{/* <Toggle input={{ value: toggled }} onToggle={handleToggle} theme={'visibility'} /> */}
			</Flex>
			<Box w="full" sx={!toggled ? inactiveStyles : {}}>
				{children}
			</Box>
		</>
	)
}

export default GiftCardVisibility
