import React from 'react'
import { Image, Text, Heading, VStack, Button } from '@chakra-ui/react'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { useRoleContext } from '../../../../../contexts/RoleProvider'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import useImageGenerator from '../../../../../hooks/useImageGenerator'
import { useColorModeValue } from '@chakra-ui/react'
import GiftCardContributionBox from '../molecules/GiftCardContributionBox'
import FindUniqueGiftsBox from '../molecules/FindUniqueGiftsBox'
import Card from '../../../../atoms/Card'
import GiftCardVisibility from '../molecules/GiftCardVisibility'
import useScrollTopOnMount from '../../../../../hooks/useScrollTopOnMount'

const RSVPSuccess = () => {
	useScrollTopOnMount()
	const { event } = useEventContext()
	const { role } = useRoleContext()

	let creator = event.creator?.profile
	let creatorName = creator ? `${creator.firstName}` : 'them'
	const allowCashCollection = event.cashCollectionGroup == 'everyone'
	const navigate = useNavigate()
	const handlers = useEventHandlersV2(event.uuid)

	const img = useImageGenerator({
		path: '/assets/images/graphics/submission-check',
		ext: 'png',
	})

	const color = useColorModeValue('dark', 'light')

	return (
		<>
			<Helmet>
				<title>VidDay - Success</title>
			</Helmet>

			<VStack spacing="0.5rem" mb="2rem">
				<Image src={img.src} srcSet={img.srcSet} boxSize="36px" />
				<Heading color="white" as="h3" variant="hero" size="md">
					Thank you!
				</Heading>
				<Text textAlign="center" maxW="460px">
					{creatorName} will be on the look out for your submission.
				</Text>
				<Button
					h="2.25rem"
					color={color}
					variant="outline"
					onClick={() => navigate(handlers.public_root)}
					colorScheme="whiteAlpha">
					Back to Invite
				</Button>
			</VStack>

			{role == 'creator' && (
				<Card mb="1rem">
					<GiftCardVisibility event={event}>
						<GiftCardContributionBox role={role} />
					</GiftCardVisibility>
				</Card>
			)}

			{role != 'creator' && allowCashCollection && (
				<Card mb="1rem">
					<GiftCardContributionBox role={role} />
				</Card>
			)}

			<Card mb="2rem">
				<FindUniqueGiftsBox event={event} />
			</Card>
		</>
	)
}

export default RSVPSuccess
