import React from 'react'
import { Flex, Image } from '@chakra-ui/react'

export default () => {
	return (
		<Flex justify="center" pb={['1rem', '1rem']}>
			<Image
				src="/assets/images/gift-card/card-services.png"
				srcSet="/assets/images/gift-card/card-services@2x.png 2x, /assets/images/gift-card/card-services.png 1x"
				width="208px"
				height="19px"
			/>
		</Flex>
	)
}
