import React from 'react'
import useImageGenerator from '../../../../../../hooks/useImageGenerator'
import { Box, Image, VStack, Text } from '@chakra-ui/react'
import DonationSelector from '../../../../GiftCollection/DonationSelector'
import { useEventRecipients } from '../../../../../../hooks/useRecipients'
import { isMobile } from 'react-device-detect'

const GiftCardContributionBox = ({ role, disabled }) => {
	const recipients = useEventRecipients()

	const img = useImageGenerator({
		path: `/assets/images/graphics/${isMobile ? 'submission-mobile-giftcard-cover' : 'submission-giftcards-cover'}`,
		ext: 'jpg',
	})

	let description = `Funds will be collected and ${recipients.firstname} can choose a gift card from hundreds of popular choices.`
	if (role == 'creator') {
		description = `${recipients.firstname} claims pooled funds towards a gift card with hundreds of popular choices from brands everyone loves.`
	}

	return (
		<>
			<Box w="full" p="1rem">
				<Image src={img.src} srcSet={img.srcset} borderRadius="0.5rem" />
			</Box>
			<Box w="full" padding="1rem">
				<VStack spacing="1rem" alignItems="center">
					<Box w="full" textAlign="center">
						<Text fontWeight="bold" variant="title">
							Want to chip in on a gift card for {recipients.formatted}?
						</Text>
						<Text maxW="400px" mx="auto">
							{description}
						</Text>
					</Box>
					<DonationSelector disabled={disabled} />
				</VStack>
			</Box>
		</>
	)
}

export default GiftCardContributionBox
