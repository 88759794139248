import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Box } from '@chakra-ui/react'

/**
 * Video src and poster should be in the same location with the same name
 * @param {string} src video and poster path with no extention eg: https://static.vidday.com/videos/vidday-group-videos-getting-started-2-minute-tour
 * @returns
 */
const ModalVideo = ({ onClose, isOpen, path, poster, ...rest }) => {
	const { ratio } = rest

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} {...rest}>
			<ModalOverlay />

			<ModalContent p="0">
				<ModalCloseButton color="white" top="-2.5rem" right="0" />
				<ModalBody>
					<Box w="100%" maxW="720px">
						<video
							width="100%"
							height="auto"
							style={{ maxHeight: '76.5vh' }}
							controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
							onError={(error) => console.error(error)}
							controlsList={['nodownload']}
							onContextMenu={(e) => {
								e.preventDefault()
							}}
							poster={`${poster}`}
							{...rest}>
							<source src={`${path}`} type={'video/mp4'} />
						</video>
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ModalVideo
