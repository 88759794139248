import React from 'react'
import { chakra, Flex, StackDivider, Box, Heading, HStack, VStack, Text } from '@chakra-ui/react'
import { LightButton } from '../../../../../atoms/LightButton'
import { VideoCameraFrontRounded, FileUploadRounded } from '../../../../../atoms/Icon'
import { isBrowser, isMobile } from 'react-device-detect'

const articles = [
	{
		label: 'Phone',
		title: 'How to record a video',
		Icon: VideoCameraFrontRounded,
		link: 'https://help.vidday.com/how-to-record-a-video-with-a-phone',
	},
	{
		label: 'Computer',
		title: 'How to record a video',
		Icon: VideoCameraFrontRounded,
		link: 'https://help.vidday.com/how-to-record-a-video-with-a-webcam',
	},
	{
		label: 'Phone',
		title: 'How to upload a video',
		Icon: FileUploadRounded,
		link: 'https://help.vidday.com/how-to-upload-media-using-a-phone',
	},
	{
		label: 'Computer',
		title: 'How to upload a video',
		Icon: FileUploadRounded,
		link: 'https://help.vidday.com/how-to-upload-media-on-a-computer',
	},
]

const Article = ({ item: { Icon, label, title, link, path } }) => {
	const handleOnClick = () => {
		window.open(link, '_blank')
	}

	return (
		<Box
			w="full"
			__css={{
				'*': {
					cursor: 'pointer !important',
				},
			}}
			onClick={handleOnClick}>
			<Flex justify="space-between" w="full" p="1rem" _hover={{ cursor: 'pointer' }}>
				<HStack spacing="1rem">
					<Icon />
					<Text>
						<chakra.span color="white">
							<strong>{label}:</strong>
						</chakra.span>{' '}
						{title}
					</Text>
				</HStack>
				<LightButton />
			</Flex>
		</Box>
	)
}

const HelpfulArticles = ({ ...rest }) => {
	const items = articles.map((el, i) => {
		if (isBrowser && el.label == 'Computer') return <Article item={el} key={i} />
		if (isMobile && el.label == 'Phone') return <Article item={el} key={i} />
	})
	return (
		<Box {...rest}>
			<Heading size="md" color="white" my="2rem">
				Helpful Articles
			</Heading>

			<VStack
				w="full"
				borderY="1px"
				borderColor="rgba(255, 255, 255, 0.1)"
				py="0.5rem"
				divider={<StackDivider borderColor="rgba(255, 255, 255, 0.1)" />}>
				{items}
			</VStack>
		</Box>
	)
}
export default HelpfulArticles
