import React from 'react'
import { HStack, Button } from '@chakra-ui/react'

/** Define values allowed as default */
const values = [15, 25, 50, 'custom']

/**
 * Renders all pricing options used for the form.
 */
export default ({ value, setValue }) => {
	const buttons = values.map((val, i) => {
		return (
			<Button
				key={i}
				variant="cashContributionButton"
				isActive={value == val ? true : false}
				onClick={() => setValue(val)}
				minW={['65px', '78px']}>
				{val != 'custom' ? `$${val}` : 'Custom'}
			</Button>
		)
	})

	return (
		<HStack justify="center" spacing="0.5rem">
			{buttons}
		</HStack>
	)
}
